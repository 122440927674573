import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "stiff-after-exercise-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I was introduced to this powder by my son, Shawn. I had commented to him that, after I went rock climbing, trampolining, obstacle course, I would spend hours in aches and pains that day and it would take two days to mostly recover.`}</p>
    <p>{`We were about to go to our favorite gym (Injanation), where I try to climb walls as fast as possible (I'm known as the 'old fat guy that climbs fast')`}</p>
    <p>{`Update 2020.10.01 Sadly, Injanation is one of the COVID-19 companies that the Alberta and Canadian Government has destroyed.`}</p>
    <p>{`He said "here, try this powder 30 minutes before we go, it will probably help you be less stiff."`}</p>
    <p>{`I trusted him, so I tried it.`}</p>
    <p>{`Afterwords, my degree of stiffness was at least 90% less that it usually was. The day after I was noticeably less stiff than usual as well.`}</p>
    <p>{`Note: I know that some people say it isn't the 'day after' that you are stiff, it is the second day. But I've never experienced that. If I'm going to be stiff, it is the day after, and the 2nd day I have noticeably healed, not feeling/getting worse.`}</p>
    <p>{`But then I also break the rule of not going into a hot tub after exercising. I go and have a good soak after exercising hard. `}</p>
    <p>{`I sometimes wonder ... is it macho people who do the ice bath solution after heavy exercise? `}</p>
    <p>{`Or is it that I am a wuss, and my definition of 'hard exercise', like racing 2 days up and down mountains in temperatures that dipped to -65C with windchill, like I did when I was 57 years old is just simply not 'hard exercise', after all, half of the kids completed the race before me, only one other participant was outside the 17-30 age range, he was also 57 and - his team came in dead last, so I guess that shows I don't do 'hard exercise'.`}</p>
    <p>{`So I don't know how much is because of me, because of my bad habit (soaking in a hot tub with sore instead of cold water), but I do know this, Shawn's magic powder really helps me minimize the aches and pains of hard exercise, and i've heard many other people make the same claim.`}</p>
    <p>{`So ... if you find you have aches and pains after heavy exercising, be it the same day, the 2nd day after or, like me, the day after, I recommend you try this magic powder.`}</p>
    <h1>{`The "not required" notice:`}</h1>
    <p>{`Note I make NOTHING from you following my advice. These are all my opinions and no one paid me to post them. I put them up because I like to help other people.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      